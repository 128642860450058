body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-image: url('20221113_154605.jpg'); /* Replace 'mountains-clouds.jpg' with your actual image file */
  background-size: cover;
  background-repeat: no-repeat;
  color: #333;
}

.App-header {
  background-color: rgba(0, 0, 0, 0.1); /* Adding a semi-transparent background to improve readability */
  color: white;
  padding: 20px;
  text-align: center;
}

.container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.6); /* Adding a semi-transparent white background for better contrast */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: black;
  font-weight: bold;
}

footer {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.3); /* Adding a semi-transparent background to footer */
  color: white;
  a, a:hover, a:active, a:visited { color: white;text-decoration: none; }
}
